<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{title}}</span>
                <span class="head__sub-title">&nbsp;/&nbsp;{{subtitle}}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling pfull__12">
                <FieldGroup_input
                        :field-group_class="'mb__12'"
                        :id="'geofence__name'"
                        :label="$t('Geofence.Name')"
                        :error="errors.name"
                        v-model="geozone_name"
                />
                <FieldGroup_textarea
                        :field-group_class="'mb__32'"
                        :id="'geofence__description'"
                        :label="$t('Geofence.Description')"
                        :error="errors.descr"
                        v-model="geozone_descr"
                />

                <h3 class="form-subtitle mb__12 mt__16" >{{$t('Geofence.Notifications')}} :</h3>
                <CheckboxControl
                    v-for="geoNotification in geoNotifications"
                    :key="geoNotification.id"

                    :control-class="'mb__12'"
                    :label="$t('Geofence.'+geoNotification.name)"
                    v-model="notifs[geoNotification.id]"
                />

                <h3 class="form-subtitle mb__12 mt__32">{{ $t('Geofence.Color geofence') }} :</h3>
                <FieldGroup_color_chooser
                    v-model="geozone_color"
                />

                <h3 class="form-subtitle mb__12 mt__32">{{ $t('Geofence.Parameters') }}:</h3>
                <div class="card card_type_table-2-columns ptb__8 mb__12" :data-id="geozoneId">
                    <table class="table">
                        <tr>
                            <th>{{ $t('text.Center') }}:</th>
                            <td>{{center}}</td>
                        </tr>
                        <tr>
                            <th>{{ $t('text.Radius') }}:</th>
                            <td>{{radius}} km</td>
                        </tr>
                        <tr>
                            <th>{{ $t('text.Area') }}:</th>
                            <td>{{area}} km²</td>
                        </tr>
                    </table>
                </div>
            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{action}} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {required} from "vuelidate/lib/validators";
    import {diffObjectsRecursiveExceptions} from "@/lib/lib";

    export default {
        name: "GeozoneEdit",
        props: [
            'geozoneId',
            'options',
            'time',
        ],
        components: {},
        data() {
            return {
                geozone: {},
                org: null,
                oldId: null,
                oldTime: null,
                displayPicker: false,
                errors: {},
                notifs: {},
            }
        },
        validations: {
            geozone: {
                name: { required },
            }
        },
        computed: {
            ...mapGetters([
                "geozonesByIds",
                "getGeoitemEdit",
                "notifications",
            ]),
            geoNotifications() {
                return this.notifications.filter(n => {
                    return (n.creator == 1) && (n.type0 == 2 || n.type0 == 3)
                })
                .reduce((out, notif) => {
                    out.push({
                        id: notif.id,
                        name: notif.name,
                        geozones: notif.geozones,
                        enabled: notif.geozones.includes(this.geozoneId)
                    })
                    return out
                }, [])
            },
            item(){
                return this.getGeoitemEdit || {color: '#00BB3F'}
            },
            center(){
                if(!this.item.points || this.item.points.length!==1) return ''
                let center = this.item.points[0]
                if (center.length > 2) center = center.slice(0, 2);
                if (center.length < 2) return ''
                return `${center[0]} , ${center[1]}`
            },
            radius(){
                return (!this.item.radius)? 0 : Math.round(this.item.radius)/1000
            },
            area(){
                return (!this.item.area)? 0 : Math.round(this.item.area/1000)/1000
            },
            geozoneName(){
                return  (this.geozoneId>0) ? this.geozone.name : this.item.name
            },
            geozone_name: {
                get () {
                    return this.item.name || ''
                },
                set (name) {
                    this.patchGeoitemEdit({name})
                },
            },
            geozone_descr: {
                get () {
                    return this.item.descr || ''
                },
                set (descr) {
                    this.patchGeoitemEdit({descr})
                },
            },
            geozone_color: {
                get () {
                    return this.item.color || '#00BB3F'
                },
                set (color) {
                    this.patchGeoitemEdit({color: color})
                }
            },
            _geozone(){
                return this.geozonesByIds[this.geozoneId]
            },
            title(){
                return (this.geozoneId>0) ? this.geozone.name : this.$t('Geofence.title.Create geofence')
            },
            subtitle(){
                return (this.geozoneId>0) ? this.$t('Geofence.subtitle.Geofence setting') : ''
            },
            action(){
                return (this.geozoneId>0) ? this.$t('buttons.Save') : this.$t('buttons.Create')
            }
        },
        watch: {
            geoNotifications: {
                handler: function (newGeoNotifs, oldGeoNotifs) {
                    let diff
                    let newIds = newGeoNotifs.map(n => n.id)
                    let oldIds = oldGeoNotifs.map(n => n.id)
                    diff = newIds.filter(id => {
                        return !oldIds.includes(id)
                    })
                    if(diff.length){
                        //console.log('geoNotifications+', newGeoNotifs, oldGeoNotifs, diff)
                        diff.forEach(id => {
                            let notif = newGeoNotifs.find(n => { return n.id == id})
                            this.notifs[id] = notif.geozones.includes(this.geozoneId)
                        })
                    }
                    diff = oldIds.filter(id => {
                        return !newIds.includes(id)
                    })
                    if(diff.length){
                        //console.log('geoNotifications-', newGeoNotifs, oldGeoNotifs, diff)
                        diff.forEach(id => {
                            delete this.notifs[id]
                        })
                    }
                },
                deep: true//???
            }
        },
        methods: {
            ...mapActions([
                "fetchNotificationsWith",
                "updateGeozoneProp",
                "patchGeoitemEdit",
                "setGeoitemEdit",
                "saveGeozone",
                "saveNotificationGeozone",
                "setPopup",
            ]),
            validateGeozone(){
                this.$v.$touch()
                this.errors = {}
                if (this.$v.$invalid) {
                    if(!this.$v.geozone.name.required) {
                        this.errors.name= 'Name is required'
                    }
                }
            },
            onCancel(){
                this.setGeoitemEdit(null)
                this.$emit('close-dialog')
            },
            onSave(){
                // this.validateGeozone();
                if(!this.getGeoitemEdit.points && !this.getGeoitemEdit.line){
                    this.$toast.warning(this.$t("no points!"))
                    return false;
                }
                if(Object.keys(this.errors).length)
                    return false // stop here if form is invalid

                //ToDo fix
                let action = (this.geozoneId>0) ? this.$t('buttons.Save') : this.$t('buttons.Create')
                this.setPopup({
                    title: `${action} geofence ${this.geozoneName}`,
                    methods: {
                        ok: () => this.onSaveGeozone(),
                        //cancel: () => {},
                    }
                })
            },
            onSaveGeozone(){
                let geoitem = this.getGeoitemEdit
                if(geoitem.id) {
                    let org = JSON.parse(this.org)
                    let diffUnit = diffObjectsRecursiveExceptions(org, geoitem)
                    geoitem = { id: geoitem.id, ...diffUnit}
                }
                // console.log(geoitem)
                this.saveGeozone(geoitem)
                    .then((res) => {
                        //console.log(res)
                        Object.entries(this.notifs).forEach(([notifId, enabled]) => {
                            //console.log(notifId, enabled)
                            this.saveNotificationGeozone({notifId, enabled, id_geozone: res.data.id})
                                .then((/*res*/) => {
                                    //console.log(res)
                                    this.fetchNotificationsWith()
                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                        })
                        this.errors = {}
                        this.$toast.success(this.$t("success!"))
                        this.setGeoitemEdit(null)
                        this.$emit('close-dialog')
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                            if(error.response.status == 422){
                                this.errors = error.response.data.reduce((out, error) => {
                                    out[error.field] = error.message
                                    return out
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            },
            updateGeozoneEdit(){
                this.oldTime = this.time
                this.oldId = this.geozoneId
                this.geozone = {}
                let item = {type: 'circle', geozoneId: -1, name: '', descr: '', color: '#00BB3F'}
                if(this.geozoneId > 0) {
                    this.geozone = {...this.geozonesByIds[this.geozoneId]}
                    item = {...this.geozone, geozoneId: this.geozoneId}
                }
                if(this.options) item = {...item, ...this.options}
                this.setGeoitemEdit(item)
                this.fetchNotificationsWith()
                this.notifs = this.geoNotifications.reduce((out, n) => {
                    out[n.id] = n.geozones.includes(this.geozoneId)//n.enabled
                    return out
                }, {})
                this.errors = {}
                this.org = JSON.stringify(item)
            },
        },
        created() {
        },
        updated() {
            //console.log('GeozoneEdit updated', this.geozoneId)
            if(!this.time){
                this.setGeoitemEdit(null)
                this.$emit('close-dialog')
            }else
            if(this.oldTime !== this.time)
            {
                //console.log('!=', this.oldTime, this.time)
                this.updateGeozoneEdit()
            }
        },
        mounted() {
            //console.log('GeozoneEdit mounted', this.geozoneId)
            if(!this.time){
                this.setGeoitemEdit(null)
                this.$emit('close-dialog')
            }else{
                this.updateGeozoneEdit()
            }
        },
        beforeDestroy() {
            this.setGeoitemEdit(null)
        },
    }
</script>

